<template>
<div>
<van-nav-bar
      class="backBox"
      :title="monthType ? '月卡开票' : '停车开票'"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="group_11">
      <div class="box_10">
        <span class="text_3">可开票订单</span>
         <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            success-text="刷新成功"
            :disabled="errorFlag"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="没有更多了"
              :error.sync="error"
              error-text="请求失败，点击重新加载"
              @load="onLoad"
            >

        <div class="group_4" v-for="item in billForMonthData" :key="item.id">
          <!-- common头部：车牌 金额 -->
          <div class="group_12">
            <div class="plateNum">
              <div class="image-text_3">
                <!-- <van-checkbox-group @change="checkboxChange"> -->
                  <!-- <div class="weui-cell__hd"> -->
                    <van-checkbox :disabled="checkBoxDisabled(item)" v-model="item.checked" @change="checkboxChange(item)" />
                  <!-- </div> -->
                <!-- </van-checkbox-group> -->
                <span class="text_4">{{ item.plate_number }}</span>
              </div>
              <div class="text-wrapper_1">
                <span class="text_5">{{
                  item.bill_status !== BILL_STATUS_SUCCESS ? '可开票' : '已开票'
                }}</span>
              </div>
            </div>
            <span class="text_6">￥{{ item.actually_paid / 100 }}</span>
          </div>
          <div class="lineline"></div>

          <!-- 月卡发票模块 -->
          <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">缴费类型</span>
            <!-- <span class="text_8">{{ item.type == 1 ? '月卡充值' : '停车缴费' }}</span> -->
            <span class="text_8">停车缴费</span>
          </div>
          <div class="text-wrapper_12" v-if="monthType">
            <span class="text_7">缴费时间</span>
            <span class="text_8">{{ item.date }}</span>
          </div>
          <!-- 停车发票模块 -->
          <div class="text-wrapper_12" v-if="monthType">
            <span class="text_7">停车时间</span>
            <span class="text_8">{{item.parkTime}}</span>
          </div>
          <!-- <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">停车场</span>
            <span class="text_8"></span>
          </div> -->
          <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">停车时长</span>
            <span class="text_8">{{parkTimeFormatter(item.local_info.parked_time)}}</span>
          </div>
          <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">入场时间</span>
            <span class="text_8">{{timeFormatter(item.local_info.start_time)}}</span>
          </div>
          <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">出场时间</span>
            <span class="text_8">{{timeFormatter(item.local_info.end_time)}}</span>
          </div>
          <!-- <div class="text-wrapper_12" v-if="!monthType">
            <span class="text_7">支付方式</span>
            <span class="text_8">微信支付</span>
          </div> -->
        </div>
        <!-- 空状态 -->
        <div class="group_4" v-if="billForMonthData.length === 0">
              <van-empty
                description="您还没有可开票订单"
              />
        </div>

           </van-list>
          </van-pull-refresh>
      </div>
    </div>
    <div class="box_11" v-if="billForMonthData.length > 0">
      <div class="text-wrapper_6">
        <span class="text_17">共：</span>
        <span class="text_17">{{ moneyNum.toFixed(2) }}</span>
        <span class="text_17">元</span>
      </div>
      <div class="image-text_5">
            <!-- <van-checkbox v-model="checkAll" @click="checkboxChangeALL" /> -->
        <!-- <span class="text-group_1">全选</span> -->
      </div>
      <van-button @click="openDetail" class="text-wrapper_7">
        <span class="text_20">下一步</span>
      </van-button>
    </div>
  </div>
  </div>
</template>
<script>
import * as common from '../../../api/common'
import * as constant from '../../../api/constant'
import * as util from '../../../api/util'
// import * as common from '../../../api/common'
import { mapGetters, mapState } from 'vuex'
import { Toast } from 'vant'
import axios from 'axios'
export default {
  data () {
    return {
      type: null,
      monthType: null, // 1月卡  2停车
      BILL_STATUS_DEFAULT: 1,
      BILL_STATUS_SUCCESS: 2,
      BILL_STATUS_FAIL: 3,
      checkAllItem: [{
        value: '0',
        name: '全选',
        checked: false
      }],
      checkItem: [{
        value: '0',
        name: '选中',
        checked: false
      }],
      checkAll: false,
      billForMonthData: [
      //   {
      //   id: 0,
      //   plateNum: '浙A00001',
      //   money: '99',
      //   type: '1',
      //   date: '2023-06-06',
      //   checked: false,
      //   checkedVal: 't0',
      //   billOkFlag: true,
      //   parkName: '银湖停车场'

        // },
        // {
        //   id: 1,
        //   plateNum: '浙A00002',
        //   money: '99',
        //   type: '1',
        //   date: '2023-06-06',
        //   checked: false,
        //   checkedVal: 't1',
        //   billOkFlag: false,
        //   parkName: '银湖停车场'

      // },
      // {
      //   id: 2,
      //   plateNum: '浙A00003',
      //   money: '99',
      //   type: '1',
      //   date: '2023-06-06',
      //   checked: false,
      //   checkedVal: 't2',
      //   billOkFlag: false,
      //   parkName: '银湖停车场'
      // },
      // {
      //   id: 3,
      //   plateNum: '浙A12345',
      //   money: '99',
      //   type: '1',
      //   date: '2023-06-06',
      //   checked: false,
      //   checkedVal: 't3',
      //   billOkFlag: false,
      //   parkName: '银湖停车场'
      // }
      ],

      selList: [],
      infosList: [],
      moneyNum: 0,
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      parkCanOpenBill: null,
      isChecking: false,

      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false
    }
  },
  mounted () {
    console.log('月卡开票还是停车开票', this.$route.query)
    if (this.$route.query && this.$route.query.type) {
      if (Number(this.$route.query.type) === 1) {
        this.monthType = true
      } else {
        this.monthType = false
      }
    } else {
      this.monthType = false
    }
    this.doQuery()
  },
  computed: {
    ...mapState({
      sid: state => state.sid.sid,
      openid: state => state.openID.openID

    }),
    ...mapGetters(['vhColumns'])
  },
  methods: {
    parkTimeFormatter (val) {
      return util.parkTimeformat(val)
    },
    timeFormatter (para) {
      return common.format(new Date(para), 'yyyy-MM-dd hh:mm:ss')
    },
    onRefresh () {
      this.billForMonthData = []
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    onLoad () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshing) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.doQuery()
      } else {
        if (this.errorFlag) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlag = false
          this.doQuery()
          return false
        }
        // 触底加载
        // if (this.billForMonthData.length >= this.pageOption.total) {
        //   this.finished = true
        // } else {
        this.pageOption.page_no = this.pageOption.page_no + 1
        this.doQuery()
        // }
      }
    },
    checkBoxDisabled (item) {
      if (item.bill_status === this.BILL_STATUS_SUCCESS) {
        return true
      }
      if (this.selList.length === 1) {
        if (Number(this.selList[0].id) === Number(item.id)) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    countMoney (row) {

    },
    async doQuery () {
      if (!this.sid) {
        Toast.fail('车场编码找不到！')
        return
      }
      // 从今天算 往前推半年时间的日期
      const nowDate = new Date()
      const startTim = new Date(nowDate.getFullYear(), nowDate.getMonth() - 6, nowDate.getDate())
      const startDateTime = common.format(startTim, 'yyyy-MM-dd hh:mm:ss')
      console.log('startDateTime', startDateTime)
      try {
        const { data } = await axios({
          url: common.cloudPayUrl + '/pub/pay.bill.park_plate.park_server.get_park_vh_pay_order',
          method: 'POST',
          data: {
            sid: this.sid,
            openid: this.openid,
            page_no: this.pageOption.page_no,
            page_size: this.pageOption.page_size,
            begin_time: startDateTime
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        if (data.code === common.resCode.OK) {
          this.billForMonthData = this.billForMonthData.concat(data.list)
          this.finished = data.list.length < this.pageOption.page_size
        } else {
          this.finished = true
        }
        this.loading = false
        this.refreshing = false
      } catch (e) {
        this.loading = false
        this.refreshing = false
      }
    },
    checkboxChange (row) {
      console.log('点击选中', row)

      if (row.checked) {
        this.selList.push(row)
        // this.countMoney(row)
        this.moneyNum += Number(row.actually_paid / 100)
      } else {
        // 取消选中
        this.selList.forEach((x, xindex) => {
          if (Number(x.id) === Number(row.id)) {
            row.checked = false
            this.selList.splice(xindex, 1)
          }
        })
        this.moneyNum -= Number(row.actually_paid / 100)
      }
      if (this.selList.length === this.billForMonthData.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }

      console.log('选中的列表', this.selList)
      this.checkBoxDisabled(row)
    },
    checkboxChangeALL (e) {
      console.log('点击全选', this.checkAll, e)
      console.log('点击全选', this.billForMonthData)
      if (this.checkAll) {
        this.billForMonthData.forEach((item, index) => {
          item.checked = true
        })
      } else {
        // 取消全选
        this.selList.forEach((item, index) => {
          item.checked = false
        })
      }
    },
    openDetail () {
      // this.$router.push('/invoiceMessage',)
      // Number(this.monthType) 1月卡  0停车
      console.log('当前选择的列表', this.selList[0])

      const item = this.selList[0]
      console.log('当前选择的列表', item)
      if (this.moneyNum === 0) {
        Toast.fail('请选择金额大于0的订单进行开票')
        return
      }
      if (!this.moneyNum) {
        Toast.fail('请先选择一条记录')
        return
      }
      if (item.bill_info) {
        if (item.bill_info.invoiceUrl) {
          window.location.href = item.bill_info.invoiceUrl
        } else {
          this.$toast('未知状态')
        }
      } else {
        if (this.parkCanOpenBill) {
          console.log('parkCanOpenBill', item)
          console.log('parkCanOpenBill', this.selList[0])
          this.toOpenBill(item)
        } else {
          console.log('checkParkOpen', item)
          console.log('checkParkOpen', this.selList[0])
          this.checkParkOpen(item)
        }
      }
    },
    toOpenBill (item) {
      console.log('选择开票的数据', item)
      if (!this.sid || !item.plate_number || !item.parkcloud_order_sn || !item.actually_paid) {
        Toast.fail('数据加载失败')
        return
      }
      const applyBillInfo = {
        sid: this.sid,
        vh_p_number: item.plate_number,
        fee_order_sn: item.parkcloud_order_sn,
        actually_paid: item.actually_paid
      }
      this.$store.commit(constant.keyApplyBillInfoVuex, applyBillInfo)
      this.$router.push({
        name: 'invoiceMessage',
        params: { type: this.monthType, num: this.moneyNum, applyDirec: 0 }
      })
    },
    checkParkOpen (item) {
      console.log('checkParkOpen', item)
      if (this.isChecking) {
        return
      }

      const that = this

      that.isChecking = true
      axios({
        url: common.cloudPayUrl + '/pub/pay.bill.check_channel',
        method: 'POST',
        data: {
          sid: that.sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(response => {
        console.log('是否开通了发票', response)
        that.isChecking = false
        const res = response.data
        if (res.code === common.resCode.OK) {
          that.parkCanOpenBill = true
          console.log('@@@1', item)
          console.log('@@@12', that.selList[0])
          that.toOpenBill(that.selList[0])
        } else {
          console.log('response.msg', res.msg)
          that.$toast.fail(res.msg || '加载失败，请重试')
        }
      }).catch((e) => {
        that.$toast.fail(e || '加载异常，请重试')
        that.isChecking = false
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.lineline {
  height: 0.005rem;
  background-color: #E2E2E2;
  margin-top: 0.07rem;
  margin-bottom: 0.07rem;

}
.group_11 {
  display: flex;
  flex-direction: column;
  padding: 0.16rem 0.16rem 0 0.16rem;
  justify-content: space-between;
  .box_10 {
    display: flex;
    flex-direction: column;
    margin: 0.05rem 0 0 0.01rem;
    padding-bottom: 0.5rem;
    .text_3 {
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
      margin-right: 2.74rem;
      margin-bottom: 0.05rem;
    }
    .group_4 {
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: column;
      padding: 0.13rem 0.02rem 0.16rem 0;
      margin-bottom: 0.16rem;
      .group_12 {
        width: 3.23rem;
        align-self: center;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        .plateNum {
          display: flex;
          flex-direction: row;
          .image-text_3 {
            width: 1.04rem;
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            .text_4 {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
            }
          }
          .text-wrapper_1 {
            background-color: rgba(232, 232, 232, 1);
            display: flex;
            flex-direction: column;
            margin: 0.02rem 0 0.01rem 0.07rem;
            padding: 0.01rem 0.14rem 0.01rem 0.2rem;
            .text_5 {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
            }
          }
        }
        .text_6 {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.16rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.22rem;
          // margin-left: 1.47rem;
        }
      }

      .text-wrapper_12 {
        width: 3.22rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.15rem 0.18rem 0 0.1rem;
        .text_7 {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }

        .text_8 {
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
        }
      }
    }
  }
}

.box_11 {
  // width: 750rpx;
  flex-direction: row;
  display: flex;
  align-items: center;
  /* margin-bottom: 46rpx; */
  margin-top: 0.07rem;
  position: fixed;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  /* background-color: #aff5b4; */
  .text-wrapper_6 {
    width: 1.46rem;
    height: 0.25rem;
    overflow-wrap: break-word;
    font-size: 0;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.2rem;
    margin: 0.13rem 0 0.12rem 0.16rem;
    .text_17 {
      height: 0.25rem;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
  .image-text_5 {
    width: 0.56rem;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 0.15rem 0 0.15rem 0.48rem;
    .text-group_1 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
  .text-wrapper_7 {
    background-color: #4797EA;
    border-radius: 0.04rem;
    margin-left: 0.17rem;
    display: flex;
    flex-direction: column;
    padding: 0.13rem 0.38rem;
    margin-bottom: 0.08rem;
    .text_20 {
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 0.16rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.22rem;
    }
  }
}
</style>
